const actionTypes = {
	SAVE_PARAMS_FOR_FETCH_CONFIG: "SAVE_PARAMS_FOR_FETCH_CONFIG",
	FETCH_CONFIG_START: "FETCH_CONFIG_START",
	FETCH_CONFIG_SUCCESS: "FETCH_CONFIG_SUCCESS",
	FETCH_CONFIG_FAILED: "FETCH_CONFIG_FAILED",
	FETCH_VALIDATE_START: "FETCH_VALIDATE_START",
	FETCH_VALIDATE_SUCCESS: "FETCH_VALIDATE_SUCCESS",
	FETCH_VALIDATE_FAILED: "FETCH_VALIDATE_FAILED",
	SET_MEETING_URL: "SET_MEETING_URL",
	SET_ERROR :'SET_ERROR',
	SET_ERROR_HANDLER : 'SET_ERROR_HANDLER',
    SET_ERROR_HANDLER_OFF :'SET_ERROR_HANDLER_OFF',
	SET_SHOW_MODAL: 'SET_SHOW_MODAL',
	LOCALIZATION: 'LOCALIZATION',
	SET_LOCALIZATION: 'SET_LOCALIZATION',
	DEVICE_DETAILS: 'DEVICE_DETAILS',
	SET_ZOOM_SDK_FLAG: 'SET_ZOOM_SDK_FLAG',
	SET_ZOOM_SDK_DETAILS: 'SET_ZOOM_SDK_DETAILS',
	SET_VVE_LIBPARAMS: 'SET_VVE_LIBPARAMS',
	FETCH_POST_CONSENT_START: "FETCH_POST_CONSENT_START",
	SET_PREFFEREDNAME : 'SET_PREFFEREDNAME',
	SET_DISPLAY_BANNER_OPTIONS: 'SET_DISPLAY_BANNER_OPTIONS'
};
export default actionTypes;
