import React from "react";
import { useSelector } from "react-redux";
import { Alert } from 'reactstrap';

const ALERT_TYPE_CLASSNAME_MAP = {
    warning: "warning-alert",
    default: "warning-alert"
};

const AlertBanner = () => {
    const { 
        displayBanner, 
        alertType, 
        title,
        body, 
        hideAlertIcon 
    } = useSelector(state => state.appConfig.banner);

    return  <Alert color={alertType} isOpen={displayBanner} className="vve-alert-container">
                <sub className={ALERT_TYPE_CLASSNAME_MAP[alertType] ?? ALERT_TYPE_CLASSNAME_MAP.default} 
                    hidden={hideAlertIcon}
                    data-testid="alertIcon"
                    role="img">
                </sub>
                <div className="vve-alert-text-container">
                    <div className="vve-alert-body">
                        <span className="vve-alert-title">{title}</span>
                        <span>{body}</span>
                    </div>
                </div>
            </Alert>
};

export default AlertBanner;