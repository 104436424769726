import actionTypes from "../actionTypes";
import { fetchConfigDetails } from "../../service/config.service";
import routePaths from '../../shared/paths.config';
import { getDeviceDetails } from '../../helper/commonUtils';

const saveParamsForFetchConfig = (data) => {
	return {
		type: actionTypes.SAVE_PARAMS_FOR_FETCH_CONFIG,
		payload: data
	};
}

const fetchConfigStart = (paramsPayload, navigate) => async (dispatch) => {
	dispatch({ type: actionTypes.FETCH_CONFIG_START });
	try {
		const { visitType, region, launchType } = paramsPayload;
		const { data: configDetails } = await fetchConfigDetails(launchType, visitType, region);
		dispatch(fetchConfigSuccess({...configDetails, launchType}));
		const deviceDetails = getDeviceDetails();
		deviceDetails.launcherInfo = configDetails.appLauncherDetails[deviceDetails.type]
		dispatch(setDeviceDetails(deviceDetails));
		dispatch(setZoomSDKFlag(configDetails?.appLauncherDetails?.zoomSDK || false));

		// zoomSDK Details
		const disableInvite = configDetails?.appLauncherDetails?.disableInvite || false;
		const zoomSDkLib = configDetails?.appLauncherDetails?.zoomSDKLib || null;
		const zoomSDKDetails = {disableInvite, ...zoomSDkLib};
		dispatch(setZoomSDKDetails(zoomSDKDetails))
	}
	catch (err) {
		const errorCode = err?.response?.data?.errors?.[0].code;
		if(errorCode === "1004" || errorCode === "2001") {
			navigate(routePaths.invalidURL);
		} else if(errorCode === "2002" || errorCode === "2003" || errorCode === "2006" || errorCode === "2007" || errorCode === "2018" || errorCode === "2019") {
			navigate(routePaths.expiredLink);
		} else {
			navigate(routePaths.systemUnavailable);
		}
		dispatch(fetchConfigError(err));
	}
};

const fetchConfigSuccess = (configResponse) => ({
	type: actionTypes.FETCH_CONFIG_SUCCESS,
	payload: configResponse,
});

const setDeviceDetails = (deviceDetails) => ({
	type: actionTypes.DEVICE_DETAILS,
	payload: deviceDetails,
});

const setZoomSDKFlag = (flag) => ({
	type: actionTypes.SET_ZOOM_SDK_FLAG,
	payload: flag
});

const setZoomSDKDetails = (payload) => ({
	type: actionTypes.SET_ZOOM_SDK_DETAILS,
	payload: payload
});

const fetchConfigError = (errorMessage) => ({
	type: actionTypes.FETCH_CONFIG_FAILED,
	payload: errorMessage,
});

const setVveLibParams = (payload) => ({
	type: actionTypes.SET_VVE_LIBPARAMS ,
	payload: payload,
});

const setBannerOptions = (payload) => ({
	type: actionTypes.SET_DISPLAY_BANNER_OPTIONS ,
	payload: payload,
});

export default {
	saveParamsForFetchConfig,
	fetchConfigStart,
	fetchConfigSuccess,
	fetchConfigError,
	setDeviceDetails,
	setZoomSDKFlag,
	setZoomSDKDetails,
	setVveLibParams,
	setBannerOptions
};