const GlobalConfig = {
    LANG_EN: 'en',
    LANG_ES: 'es',
    KP_LINK_TEXT: 'KaiserPermanente.org',
    KP_LINK_TEXT_MHS: 'mauihealth.org',
    WA_REGION: "WA",
    ACCEPT: 'AGREE',
    DECLINE: 'DISAGREE',
    ERROR: 'ERROR',
    DEFAULT_ERROR_JSON: {
        errors:
        {
            severity: 'ERROR',
            type: 'BUSINESS',
            code: '',
            error: '',
        }
    },
    OK: 'OK',
    KP_LOGO: 'Kaiser Permanente Logo',
    OP_VISIT_TYPE: 'OP',
    GV_VISIT_TYPE: 'GV',
    NI_VISIT_TYPE: 'NI',
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    IOS: 'ios',
    AOS: 'aos',
    CLEAR: 'clear',
    CONSENT_SUCCESS: 'Success',
    LAUNCHTYPES: {
        "direct": "DIRECT",
        "integratedweb": "INTEGRATEDWEB",
        "integratedmobile": "INTEGRATEDMOBILE",
        "vveinvite": "VVEINVITE",
    },
    LAUNCH_VVEINVITE: 'VVEINVITE',
    AMBNT_CONSENT_AGREE: 'AGREE',
    AMBNT_CONSENT_DISAGREE: 'DISAGREE',
    AMBNT_CONSENT_NOCONSENT: 'NOCONSENT'
}
export default GlobalConfig;