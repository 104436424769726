import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import Button from "../Button/Button";
import translations from "../../translations";
import GlobalConfig from "../../shared/GlobalConfig";
import agreementActions from "../../store/actions/agreementAction";
import configActions from "../../store/actions/configAction";

const ALLOWED_DESKTOP_OS = [GlobalConfig.IOS, GlobalConfig.AOS];
const ALLOWED_VISIT_TYPES = [GlobalConfig.OP_VISIT_TYPE, GlobalConfig.NI_VISIT_TYPE];

const AbridgeConsent = (abridgeConsentProps) => {

  // parse props
  const {
    visitType,
    validateConfig: { ambntlistngConsent = {}, ...validateConfigData }, 
    deviceDetails: { deviceType, deviceOs, orientation }
  } = abridgeConsentProps;

  const dispatch = useDispatch();

  // parse dependent store values
  const { lang: localizedLanguageCode } = useSelector(state => state?.localization);

  // set local state variables
  const [translation, setTranslation] = useState(translations[localizedLanguageCode]);
  const [consentVerbiageList, setConsentVerbiageList] = useState(null);
  const [bannerActiveStatus, setBannerActiveStatus] = useState(false);

  const updateStoreBannerDetails = ({displayBannerStatus, bannerText}) => {
    const actionPayload = {
      displayBanner: displayBannerStatus,
      alertType: "warning",
      hideAlertIcon: null,
      ...bannerText
    };
    dispatch(configActions.setBannerOptions(actionPayload));
  };

  const submitUserConsent = (ambntlistngConsentStatus) => {
    const abridgeConsentActionPayload = {
      validateConfigData,
      ambntlistngConsentStatus,
      vveConsentStatus: translation.ACCEPT
    };
    dispatch(agreementActions.submitAbridgeConsentAction(abridgeConsentActionPayload));
  };

  const startNoConsentSubmissionTimer = () => {
    const { noConsentBannerDisplayTimeout = null } = ambntlistngConsent;
    if(noConsentBannerDisplayTimeout) {
      setTimeout(() => {
        submitUserConsent(GlobalConfig.AMBNT_CONSENT_NOCONSENT);
        updateStoreBannerDetails({ displayBannerStatus: false, bannerText: translation.ABRIDGE_TIMEOUT_BANNER });
        setBannerActiveStatus(false);
      }, noConsentBannerDisplayTimeout);
    }
  };

  const startAbridgeIdleTimer = () => {
    const { idleTimeout = null } = ambntlistngConsent;
    if(idleTimeout) {
      setTimeout(() => {
        startNoConsentSubmissionTimer();
        updateStoreBannerDetails({ displayBannerStatus: true, bannerText: translation.ABRIDGE_TIMEOUT_BANNER });
        setBannerActiveStatus(true);
      }, idleTimeout);
    }
  };

  // action handlers
  const handleAccept = () => {
    submitUserConsent(GlobalConfig.AMBNT_CONSENT_AGREE);
  };

  const handleDecline = () => {
    submitUserConsent(GlobalConfig.AMBNT_CONSENT_DISAGREE);
  };

  // layout elements
  const AgreeButtonElement = (
    <Button modal=""
            disabled={false}
            tabIndex="0"
            refValue=""
            analyticsName=""
            onClick={handleAccept}
            class="vve-modal-agree"
            id="vve-modal-agree">
      {ALLOWED_VISIT_TYPES.includes(visitType) ? translation.ACCEPT_BTN : translation.ACCEPT_BUTTON}
    </Button>
  );

  const DeclineButtonElement = (
    <Button modal=""
            disabled={false}
            tabIndex="0"
            refValue=""
            analyticsName=""
            onClick={handleDecline}
            class="vve-modal-decline"
            id="vve-modal-decline">
      {translation.DECLINE_BUTTON}
    </Button>
  );

  const renderAmbientActionButtons = () => {
    if ((deviceType === GlobalConfig.MOBILE) || (deviceType === GlobalConfig.DESKTOP && deviceOs === (GlobalConfig.IOS || GlobalConfig.AOS)) || (deviceType === GlobalConfig.DESKTOP && orientation === 'portrait')) {
      return (
        <>
          {AgreeButtonElement}
          {DeclineButtonElement}
        </>
      )
    } else {
      return (
        <>
          {DeclineButtonElement}
          {AgreeButtonElement}
        </>
      )
    }

  };
  
  // parse and set all depndencies based on localization
  useEffect(() => {
    document.documentElement.lang = localizedLanguageCode;
    const parsedTranslation = translations[localizedLanguageCode];
    setTranslation(parsedTranslation);
    const consentLanguageKey = (localizedLanguageCode === 'en') ? "english" : "spanish";
    setConsentVerbiageList(ambntlistngConsent[consentLanguageKey] ?? null);
    updateStoreBannerDetails({ displayBannerStatus: bannerActiveStatus, bannerText: parsedTranslation.ABRIDGE_TIMEOUT_BANNER} );
  }, [localizedLanguageCode]);

  useEffect(() => {
    startAbridgeIdleTimer();
  }, []);

  return (
    <>
      { consentVerbiageList &&
        (
          <>
            <ul className='Agreement-list vve-agreement-noList'>
              { consentVerbiageList.map((content, index) => <li key={`abridge-${index}`}>{content}</li>) }
            </ul>
            <div className="vve-agreement-button-div">
              {renderAmbientActionButtons()}
            </div>
          </>
        )
      }
    </>
  );
}

AbridgeConsent.propTypes = {
  visitType: PropTypes.string,
  validateConfig: PropTypes.object,
  deviceDetails: PropTypes.object
};

export default AbridgeConsent;