import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import localizationActions from "../../store/actions/localizationActions"
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import GlobalConfig from '../../shared/GlobalConfig';

const HeaderSection = () => {
    const dispatch = useDispatch();

    const displayLanguage = useSelector(state => state?.localization?.labelName) === "English" ? 'en' : 'es';
    const showLocalization = useSelector(state => state?.localization?.show);
    const region = useSelector(state => state?.appConfig?.region);
    const lang = { en: 'English', es: 'Español' }
    
    const handleClick = () => {
        dispatch(localizationActions.selectLanguage({
            labelName: displayLanguage === 'en' ? 'Español' : 'English',
            lang: displayLanguage
        }));
    }
    
    return (
        <div className="header-container">
            <div className={`vve-header-Logo ${region}`} role="img" aria-label={GlobalConfig.KP_LOGO} alt={GlobalConfig.KP_LOGO}/>
            {showLocalization && (
                <div className='vve-toggle-lang-container'>
                    <LanguageSelector
                        onClick={handleClick}
                        defaultText={displayLanguage}
                        options={lang}
                    />  
                </div>
            )}
        </div>
    )
}

export default HeaderSection;